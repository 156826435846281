import * as React from 'react';
import { I18nextProvider } from '@wix/wix-i18n-config';
import * as get_ from 'lodash/get';

import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { Spinner } from '@wix/search-results';
import { Widget } from '../Widget/Widget';
import i18n from '../../config/i18n';

import { IWidgetRootProps } from './WidgetRoot.types';
import { addHighlightStyle, HiglightStyle } from './addHighlightStyle';

import styles from './WidgetRoot.st.css';

export class WidgetRoot extends React.PureComponent<IWidgetRootProps> {
  static defaultProps: Partial<IWidgetRootProps> = {
    isLoaded: false,
  };

  componentDidMount() {
    this.props.host.appLoadBI.loaded();
    this.props.host.registerToComponentDidLayout(this.props.onAppLoaded);
  }

  render() {
    const {
      searchResultsAbsoluteUrl,
      language,
      experiments,
      settings,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchResponseTotals,
      searchSamples,
      documentTypes,
      onQuerySubmit,
      onPageChange,
      onDocumentTypeChange,
      onDocumentClick,
      viewMode,
      isLoaded,
      isDemoContent,
      isMobile,
    } = this.props;

    console.groupCollapsed('WidgetRoot:render');
    console.log('props', this.props);
    console.log('settings', settings);
    console.groupEnd();

    const highlightStyle: HiglightStyle = get_(
      this.props,
      [
        'host',
        'style',
        'styleParams',
        'fonts',
        'resultsHighlightFont',
        'style',
      ],
      {
        bold: false,
        italic: false,
        underline: false,
      },
    );

    this.props.searchResponse.documents.forEach(document => {
      document.title = addHighlightStyle(document.title, highlightStyle);
      document.description = addHighlightStyle(
        document.description,
        highlightStyle,
      );
    });

    this.props.searchSamples.forEach(sample =>
      sample.documents.forEach(document => {
        document.title = addHighlightStyle(document.title, highlightStyle);
        document.description = addHighlightStyle(
          document.description,
          highlightStyle,
        );
      }),
    );

    return (
      <I18nextProvider i18n={i18n(language)}>
        <ExperimentsProvider options={{ experiments }}>
          {isLoaded ? (
            <Widget
              searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
              viewMode={viewMode}
              isDemoContent={isDemoContent}
              isMobile={isMobile}
              documentTypes={documentTypes}
              settings={settings}
              searchRequest={searchRequest}
              searchRequestStatus={searchRequestStatus}
              searchResponse={searchResponse}
              searchResponseTotals={searchResponseTotals}
              searchSamples={searchSamples}
              onQuerySubmit={onQuerySubmit}
              onPageChange={onPageChange}
              onDocumentTypeChange={onDocumentTypeChange}
              onDocumentClick={onDocumentClick}
            />
          ) : (
            <Spinner {...styles('spinner')} />
          )}
        </ExperimentsProvider>
      </I18nextProvider>
    );
  }
}
