import * as React from 'react';
import * as isEqual from 'lodash/isEqual';

import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';

// TODO: research why all sdk code is included in bundle.
import {
  ISearchDocument,
  ISearchProductDocument,
  SearchDocumentType,
  ISearchDocumentType,
} from '@wix/client-search-sdk';
import {
  SearchResults,
  GridLayout,
  ListLayout,
  SampleLayout,
} from '@wix/search-results';

import {
  getResultsFoundMessageKey,
  getResultsEmptyMessageKey,
} from './resultsMessage';
import { IWidgetProps, IWidgetState, ITab } from './Widget.types';
import { SearchRequestStatus } from '../../types/types';

import styles from './Widget.st.css';
import { Spec } from '../../lib/specs';
import {
  buildSearchResultsUrl,
  toLocationSearchRequest,
} from '../../platform/location';

const DOCUMENT_TYPE_TRANSLATIONS: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all',
  [SearchDocumentType.Pages]: 'searchResults.tabs.label.pages',
  [SearchDocumentType.Products]: 'searchResults.tabs.label.products',
  [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs',
  [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums',
  [SearchDocumentType.Bookings]: 'searchResults.tabs.label.bookings',
};

const DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT: Record<
  SearchDocumentType,
  string
> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all.withCount',
  [SearchDocumentType.Pages]: 'searchResults.tabs.label.pages.withCount',
  [SearchDocumentType.Products]: 'searchResults.tabs.label.products.withCount',
  [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs.withCount',
  [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums.withCount',
  [SearchDocumentType.Bookings]: 'searchResults.tabs.label.bookings.withCount',
};

const DOCUMENT_TYPE_ACCESSIBILITY_LABEL: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all.accessibilityLabel',
  [SearchDocumentType.Pages]:
    'searchResults.tabs.label.pages.accessibilityLabel',
  [SearchDocumentType.Products]:
    'searchResults.tabs.label.products.accessibilityLabel',
  [SearchDocumentType.Blogs]:
    'searchResults.tabs.label.blogs.accessibilityLabel',
  [SearchDocumentType.Forums]:
    'searchResults.tabs.label.forums.accessibilityLabel',
  [SearchDocumentType.Bookings]:
    'searchResults.tabs.label.bookings.accessibilityLabel',
};

function isAnchorElement(element: HTMLElement): element is HTMLAnchorElement {
  return element.nodeName === 'A';
}

// handle opening links in a new tab
// https://github.com/zeit/next.js/blob/42771f6451df6ba2dbda51e60968e9edcfde74af/packages/next/client/link.tsx#L149-L159
function shouldKeepDefaultBehaviour(e: React.MouseEvent<HTMLElement>): boolean {
  const currentTarget = e.currentTarget;
  return (
    isAnchorElement(currentTarget) &&
    ((currentTarget.target && currentTarget.target !== '_self') ||
      e.metaKey ||
      e.ctrlKey ||
      e.shiftKey ||
      (e.nativeEvent && e.nativeEvent.button === 1))
  );
}

class WidgetComponent extends React.Component<
  WithTranslation & InjectedExperimentsProps & IWidgetProps,
  IWidgetState
> {
  state = {
    searchQuery: this.props.searchRequest.query,
  };

  listRef: React.RefObject<HTMLDivElement> = React.createRef();

  getTitleForDocumentType = (
    documentType: SearchDocumentType,
    count?: number,
  ): string => {
    const { t } = this.props;
    let title = documentType;

    if (count !== undefined) {
      const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT[documentType];

      if (titleI18NKey) {
        title = t(titleI18NKey, {
          number: count,
        });
      }
    } else {
      const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS[documentType];

      if (titleI18NKey) {
        title = t(titleI18NKey);
      }
    }

    return title;
  };

  getAccessibilityLabelForDocumentType = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const { t } = this.props;
    let title = documentType;

    const titleI18NKey = DOCUMENT_TYPE_ACCESSIBILITY_LABEL[documentType];

    if (titleI18NKey) {
      title = t(titleI18NKey, {
        number: count,
      });
    }

    return title;
  };

  getTab = ({ documentType }: ISearchDocumentType): ITab => {
    const { searchResponseTotals } = this.props;
    const hasTotals = Boolean(searchResponseTotals);

    const title = hasTotals
      ? this.getTitleForDocumentType(
          documentType,
          searchResponseTotals[documentType] || 0,
        )
      : this.getTitleForDocumentType(documentType);

    return {
      title,
      documentType,
    };
  };

  componentDidUpdate(prevProps: IWidgetProps) {
    // TODO: review viewMode when resolved https://github.com/wix-private/native-components-infra/pull/28
    if (
      prevProps.searchRequest.query !== this.props.searchRequest.query ||
      prevProps.viewMode !== this.props.viewMode
    ) {
      this.setState({
        searchQuery: this.props.searchRequest.query,
      });
    }

    if (
      this.listRef.current &&
      !isEqual(prevProps.searchRequest, this.props.searchRequest)
    ) {
      // safari (as of 2019-11-18) ignores `preventScroll`:
      // https://bugs.webkit.org/show_bug.cgi?id=178583
      const safariCantPreventScrollX = window.scrollX;
      const safariCantPreventScrollY = window.scrollY;

      this.listRef.current.focus({
        preventScroll: true,
      });

      if (
        safariCantPreventScrollX !== window.scrollX ||
        safariCantPreventScrollY !== window.scrollY
      ) {
        window.scrollTo(safariCantPreventScrollX, safariCantPreventScrollY);
      }
    }
  }

  getTotalPages(pageSize: number, total: number | null): number {
    if (total) {
      return Math.floor(total / pageSize) + (total % pageSize === 0 ? 0 : 1);
    }
    return 0;
  }

  getTotalResultsText() {
    const {
      searchRequest,
      searchResponse,
      settings,
      isDemoContent,
      t,
    } = this.props;

    const resultsMessageKey =
      searchResponse.totalResults > 0
        ? getResultsFoundMessageKey({
            isWithNumber: settings.isResultsMessageWithNumber,
            isWithQuery: settings.isResultsMessageWithQuery,
          })
        : getResultsEmptyMessageKey({
            isWithNumber: settings.isResultsEmptyMessageWithNumber,
            isWithQuery: settings.isResultsEmptyMessageWithQuery,
          });

    const searchQuery =
      isDemoContent && !searchRequest.query
        ? t('resultsFoundMessage.demoQuery')
        : searchRequest.query;

    return t(resultsMessageKey, {
      number: searchResponse.totalResults,
      query: searchQuery,
    });
  }

  handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value });
  };

  handleQueryClear = () => {
    this.setState({ searchQuery: '' }, () => {
      this.handleSubmit();
    });
  };

  handlePageChange = (v: {
    event: React.MouseEvent<HTMLElement>;
    page: number;
  }) => {
    const { event, page } = v;

    if (shouldKeepDefaultBehaviour(event)) {
      event.stopPropagation();
      return;
    }

    const { onPageChange } = this.props;

    onPageChange(page);
  };

  handleTabChange = (tabIndex: number) => {
    const { onDocumentTypeChange, documentTypes } = this.props;

    onDocumentTypeChange(documentTypes[tabIndex].documentType, {
      source: 'tabs',
    });
  };

  handleSamplesViewAllClick = (
    e: React.MouseEvent<HTMLElement>,
    documentType: SearchDocumentType,
  ) => {
    if (shouldKeepDefaultBehaviour(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    const { onDocumentTypeChange } = this.props;

    onDocumentTypeChange(documentType, {
      source: 'samples',
    });
  };

  handleSubmit = () => {
    const { onQuerySubmit } = this.props;
    const { searchQuery } = this.state;

    onQuerySubmit(searchQuery);
  };

  handleSearchResultClick = (
    e: React.MouseEvent<HTMLElement>,
    item: ISearchDocument,
    index: number,
  ) => {
    if (shouldKeepDefaultBehaviour(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    this.props.onDocumentClick(item, index);
  };

  buildPageUrl = (page: number): string => {
    const locationRequest = {
      ...toLocationSearchRequest(this.props.searchRequest),
      page,
    };
    return buildSearchResultsUrl(
      this.props.searchResultsAbsoluteUrl,
      locationRequest,
    );
  };

  render() {
    const {
      settings,
      documentTypes,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchSamples,
      isDemoContent,
      isMobile,
      t,
      experiments,
      searchResponseTotals,
    } = this.props;

    const { searchQuery } = this.state;

    const totalPages = this.getTotalPages(
      searchRequest.paging.pageSize,
      searchResponse.totalResults,
    );

    // TODO: resolve Alignment vs TabAlignment
    const resultsMenuAlignment = settings.resultsMenuAlignment as any;
    const paginationAlignment = settings.paginationAlignment;

    const activeTabIndex = documentTypes.findIndex(
      tab => tab.documentType === searchRequest.documentType,
    );

    const tabs = documentTypes.map(this.getTab);
    const shouldShowSamples =
      experiments.enabled(Spec.SearchSamples) &&
      searchRequest.documentType === SearchDocumentType.All;

    const samplesWithTitles = shouldShowSamples
      ? searchSamples.map(s => ({
          ...s,
          accessibilityLabel: searchResponseTotals
            ? this.getAccessibilityLabelForDocumentType(
                s.documentType,
                searchResponseTotals[s.documentType] || 0,
              )
            : this.getTitleForDocumentType(s.documentType),
          title: searchResponseTotals
            ? this.getTitleForDocumentType(
                s.documentType,
                searchResponseTotals[s.documentType] || 0,
              )
            : this.getTitleForDocumentType(s.documentType),
        }))
      : null;

    const totalResultsText = this.getTotalResultsText();

    return (
      <SearchResults
        searchQuery={searchQuery}
        searchPlaceholder={settings.searchBarPlaceholder}
        searchClearLabel={t('searchResults.clearLabel')}
        isSearchBarEnabled={
          experiments.enabled(Spec.SearchBoxToggle)
            ? settings.isSearchBarEnabled
            : true
        }
        tabs={tabs}
        tabsAlignment={resultsMenuAlignment}
        activeTabIndex={activeTabIndex}
        onTabChange={this.handleTabChange}
        onQueryChange={this.handleQueryChange}
        onQueryClear={this.handleQueryClear}
        onSubmit={this.handleSubmit}
        totalResultsText={totalResultsText}
        isPaginationHidden={shouldShowSamples}
        currentPage={searchRequest.paging.page}
        totalPages={totalPages > 1 ? totalPages : null}
        paginationAlignment={paginationAlignment}
        onPageChange={this.handlePageChange}
        isLoading={searchRequestStatus === SearchRequestStatus.Loading}
        isMobile={isMobile}
        isDemoContent={isDemoContent}
        demoContentNotificationText={t(
          'searchResults.demoContentNotificationText',
        )}
        buildPageUrl={this.buildPageUrl}
        {...styles('root', { mobileView: isMobile })}
      >
        {searchRequestStatus === SearchRequestStatus.Failed && (
          <div>{t('resultsEmptyMessage.requestFailed')}</div>
        )}
        {(() => {
          switch (searchRequest.documentType) {
            case SearchDocumentType.All:
              return shouldShowSamples ? (
                <SampleLayout
                  aria-label={totalResultsText}
                  samples={samplesWithTitles}
                  onItemLinkClick={this.handleSearchResultClick}
                  onViewAllClick={this.handleSamplesViewAllClick}
                  viewAllLabel={t('searchResults.samples.label.viewAll')}
                  listRef={this.listRef}
                />
              ) : (
                <ListLayout
                  aria-label={totalResultsText}
                  items={searchResponse.documents}
                  onItemLinkClick={this.handleSearchResultClick}
                  listRef={this.listRef}
                />
              );
            case SearchDocumentType.Products:
              return (
                <GridLayout
                  aria-label={totalResultsText}
                  items={searchResponse.documents as ISearchProductDocument[]}
                  onItemLinkClick={this.handleSearchResultClick}
                  listRef={this.listRef}
                />
              );
            default:
              return (
                <ListLayout
                  aria-label={totalResultsText}
                  items={searchResponse.documents}
                  onItemLinkClick={this.handleSearchResultClick}
                  listRef={this.listRef}
                />
              );
          }
        })()}
      </SearchResults>
    );
  }
}

export const Widget = withTranslation()(withExperiments(WidgetComponent));
